import React, {useReducer, createContext} from "react";

const styleMode = window.localStorage.getItem("styleMode");

// Define the initial state for the user context
const initialState = {
    userId: null,
    userData: {
      
      email: null,
      firstName: null,
      lastName: null,
      username: null,
      displayName: null,
      photoUrl: null,
      totalDepositMade: 0.0,
      currentValue: 0.0,
      profit: 0.0,
      withdrawReq: 0,
      depositReq: 0,
      loginMethod: null,
      isProfileUpdated: false,
      isKycCompleted: false,
      isKycDocSubmitted: false,
      totalReturns: 0,
      address: '',
      isAdmin: false,
      gender: '',
      marital_status: '',
      date_of_birth: null,
      aadhar_card_front_url: null,
      aadhar_card_back_url: null,
      pan_card_url: null,
      id: null,
      phone: null,
      nomineeName: '',
      nomineeRelation:'',
    },
    styleMode: styleMode ? styleMode : "main",
    verifying: false
  };
  

// Create a context to hold the user state and dispatcher
export const UserContext = createContext(initialState);

// Define a reducer function to update the user state based on actions
const reducer = (state, action) => {
  switch (action.type) {
    case "userId":
      return { ...state, userId: action.payload }; // Update userId
    case "verifying":
      return { ...state, verifying: action.payload }; // Update verifying flag
    case "updateProfile":
      return {
        ...state,
        userData: {
          ...state.userData,
          ...action.payload // Update user profile data
        }
      };
    case "signOut":
      return { ...initialState }; // Reset to initial state (sign out)
    case "profile_updated":
      return {
        ...state,
        userData: {
          ...state.userData,
          ...action.payload // Updated user profile data
        }
      };
    default:
      return state;
  }
};



// Create a user provider component using the provided reducer and initial state
export const UserProvider = props => {
  const [userState, userDispatch] = useReducer(reducer, initialState);
    return (
      <UserContext.Provider value={{ userState, userDispatch }}>
        {props.children}
      </UserContext.Provider>
    );
  };
  