import  { auth} from "../config/firebase.js";
import { doc, getDoc, getFirestore } from "firebase/firestore"; 


const db = getFirestore()

export const getLoggedInUserData = async () => {
    try {



      const docRef = doc(db, "users", auth.currentUser.uid);
      const userSnap = await getDoc(docRef);
      if (userSnap.exists()){
                  //  && userSnap.data().phone
                    if (userSnap.data()) {
                       return userSnap.data()
            }
      }else{
            return null;
      }

    } catch (error) {
        throw error;
    }
  }

  export const getUserDataById = async (id) =>{
    try {



      const docRef = doc(db, "users", id);
      const userSnap = await getDoc(docRef);
      if (userSnap.exists()){

                    if (userSnap.data()) {
                      return   userSnap.data()
            }
      }else{
            return null;
      }

    } catch (error) {
        throw error;
    }
  }