// import './App.scss';
import { UserContext} from "./context/userContext";
import Sidenav from './components/Sidenav';









import { useState, useEffect, useContext, Suspense } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import routes from "./routes/routes";
import theme from "./assets/theme";


// Soft UI Dashboard React contexts
import { useSoftUIController } from "./context/UIController";

import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './config/firebase';
import SignIn from './pages/authentication/sign-in';
import Loader from './components/Loader';
import UserInfo from "./pages/admin/UserInfo";



export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  const { userState, userDispatch } = useContext(UserContext);
  const [initializationComplete, setInitComplete] = useState(false);
  const db = getFirestore();
  const userId = userState.userId;

  // main router functionality

  const handleUserAuth = async (auth, user) => {
    try {
      const docRef = doc(db, "users", auth.currentUser.uid);
      const userSnap = await getDoc(docRef);

      if (userSnap.exists()) {
        if (userSnap.data()) {
          userDispatch({ type: userSnap.data().isProfileUpdated ? "profile_updated" : "updateProfile", payload: userSnap.data() });
          userDispatch({ type: "verifying", payload: false });
        }
        userDispatch({ type: "userId", payload: auth.currentUser.uid });
        setInitComplete(true);
      } else {
        userDispatch({ type: "signOut" });
        setInitComplete(true);
      }
    } catch (error) {
      userDispatch({ type: "signOut" });
      setInitComplete(true);
      console.error("Error fetching user data:", error);
    }
  }


  useEffect(() => {
    const subscriber = onAuthStateChanged(auth, user => {
      if (user) {
        handleUserAuth(auth, user);
      } else {
        userDispatch({ type: "signOut" });
        setInitComplete(true);
      }
    });
    return subscriber;
  }, []);


  const routeWithAuth = destination => {
    return !userId ? (
      <Navigate to={{ pathname: "/" }} />
    ) : (
      destination
    );
  };

  const reRouteIfAuthenticated = destination => {
    return userId ? (
      
      <Navigate to={{ pathname: "/dashboard" }} />
    ) : (
      destination
    );

  };

  const reRouteIfAuthenticatedandAdmin = destination => {
    return userId && userState.userData.isAdmin ? (
      destination
    ) : (
      <Navigate to={{ pathname: "/" }} />
    );
  };



  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      // setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      // setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };




  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }



      if (route.route) {


        // return <Route exact path={route.route} element={route.component} key={route.key} />;
        return (

          <Route
            exact
            path={route.route}
            key={route.key}
            element={
              <Suspense fallback= {<Loader color="primary" open={true}/>} >
                {
                  
                  route.access_type === "with_auth" ? (
                    routeWithAuth(route.component)
                  ) : (
                    route.access_type === "with_admin_auth" ? (
                      reRouteIfAuthenticatedandAdmin(route.component)
                    ) :  (
                    route.component
                  )
                )
                }
              </Suspense>
            }
          >

          </Route>

        );
      }

      return null;
    });


  const renderApp = () => {
    return !initializationComplete ? (
      <Loader color="primary" open={true}/>
    ) : (
      <Routes>
        <Route path="/" element={reRouteIfAuthenticated(<SignIn />)} />

        {/* <Route path="/user-info/:userId" element={reRouteIfAuthenticatedandAdmin(<UserInfo />)} /> */}
       
        {/* <Route path="/all_users" element={reRouteIfAuthenticatedandAdmin(<AllUsers />)} />
        <Route path="/all_users/:id" element={reRouteIfAuthenticatedandAdmin(<UserInfo />)} />
        <Route path="/add_schemes" element={reRouteIfAuthenticatedandAdmin(<Schemes />)} /> */}

        {getRoutes(routes)}
       
      </Routes>
    );
  };

  return (
    <Suspense fallback={<Loader color="primary" open={true}/>}>
    <ThemeProvider theme={theme}>
      <CssBaseline />

      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brandName="SrxCoin"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </>
      )}

      <Suspense fallback={<Loader color="primary" open={true}/>}>
        {renderApp()}
      </Suspense>
    </ThemeProvider>
    </Suspense>
  );
}
